@use './global';
@use './variables' as *;

.logo-container {
  background-color: $bg;
  height: 100vh;
}

.gif-container {
  background-color: $bg;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-logo {
  display: block;
  width: 25vw;
  height: auto;
}

.main-logo {
  display: block;
  width: 6vw;
  height: auto;
}